import React, { useState } from "react";

const clientId = process.env.REACT_APP_SSO_CLIENT_ID;
const tenantID = process.env.REACT_APP_SSO_TENANT_ID;
const redirectURI = process.env.REACT_APP_SSO_REDIRECT_URI;

const MicrosoftSSOLogin = (props) => {
  const loginHandler = async () => {
    function generateRandomString(length) {
      const charset =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += charset.charAt(Math.floor(Math.random() * charset.length));
      }
      return result;
    }

    async function sha256(plainText) {
      const encoder = new TextEncoder();
      const data = encoder.encode(plainText);
      const hashBuffer = await crypto.subtle.digest("SHA-256", data);
      return hashBuffer;
    }

    function base64urlEncode(arrayBuffer) {
      const base64String = btoa(
        String.fromCharCode.apply(null, new Uint8Array(arrayBuffer))
      );
      return base64String
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    }

    async function generateCodeChallenge(codeVerifier) {
      const hash = await sha256(codeVerifier);
      return base64urlEncode(hash);
    }

    const codeVerifier = generateRandomString(128); // Generate a code_verifier with length 128
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // Store codeVerifier securely (in session storage, for example)
    sessionStorage.setItem("code_verifier", codeVerifier);

    const authorizationUrl = `https://login.microsoftonline.com/${tenantID}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectURI
    )}&response_mode=query&scope=https://graph.microsoft.com/.default&code_challenge=${codeChallenge}&code_challenge_method=S256&prompt=select_account`;

    window.location.href = authorizationUrl;
  };

  return (
    <img
      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/development+/MicrosoftLogo.svg"
      alt="microsoft-logo"
      onClick={loginHandler}
    />
  );
};

export default MicrosoftSSOLogin;
