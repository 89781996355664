import React, { useCallback, useEffect, useState } from "react";
import SearchComponent from "../../Components/searchComponent";
import { DropdownList } from "react-widgets";
import { ResourceAndFaqServices } from "./ResourceAndFaqServices";
import PlayButton from "./PlayButton.svg";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import CustomPagination from "../../Components/Pagination";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ComapnyDetailsDateTimeFormatter from "../../Utils/ComapnyDetailsDateTimeFormatter";
import useScreenSize from "./ScreenSize";

export const NoDataAvailable = ({ text }) => {
  return (
    <span className="d-flex justify-content-center align-item-center h-30vh f-23 fw-700">
      No {text} Available
    </span>
  );
};

function getYouTubeVideoId(url) {
  const urlObj = new URL(url);
  return urlObj.searchParams.get("v");
}
function ResourceItem(props) {
  const videoThumbnailUrl = useCallback((url) => {
    const videoId = getYouTubeVideoId(url);
    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
  }, []);
  return (
    <div className="grid-item-resources">
      <img
        src={PlayButton}
        alt="play-button"
        className="play-button cursor-pointer"
        onClick={() => props.handlePlay(props.item)}
      />
      <img
        src={videoThumbnailUrl(props.item.video_link)}
        alt="Video Thumbnail"
        className="thumbnail cursor-pointer"
        onClick={() => props.handlePlay(props.item)}
      />
      {props.item.title.length > 25 ? (
        <>
          <span
            className="item-title"
            data-for={`tooltip-${props.index}-title`}
            data-tip={props.item.title}
          >
            {props.item.title.slice(0, 25) + "..."}
          </span>
          <CustomReactTooltip id={`tooltip-${props.index}-title`} multiline />
        </>
      ) : (
        <span className="item-title">{props.item.title}</span>
      )}
    </div>
  );
}

const VideoModal = ({ isOpen, toggle, videoId, title, data }) => {
  function getYouTubeEmbedUrl(videoId) {
    const baseUrl = `https://www.youtube.com/embed/${videoId}`;
    const params = ["autoplay=1", "rel=0", "modestbranding=1", "showinfo=0"];
    return `${baseUrl}?${params.join("&")}`;
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-lg custom-modal">
      <ModalHeader toggle={toggle}>
        {title?.length > 45 ? (
          <>
            <span
              className="category-title"
              data-for={`tooltip-${title}-title`}
              data-tip={title}
            >
              {title.slice(0, 45) + "..."}
            </span>
            <CustomReactTooltip id={`tooltip-${title}-title`} multiline />
          </>
        ) : (
          <span className="category-title">{title}</span>
        )}
      </ModalHeader>
      <ModalBody
        className="d-flex justify-content-center flex-d"
        style={{ padding: "0px", marginTop: "10px" }}
      >
        {videoId && (
          <iframe
            width="520"
            height="315"
            src={getYouTubeEmbedUrl(videoId)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="youtube-player"
          ></iframe>
        )}
        <div className="d-flex" style={{ marginTop: "10px" }}>
          <span style={{ fontWeight: "700", fontSize: "14px" }}>
            Published Date:
            <span style={{ fontWeight: "400", fontSize: "14px" }}>
              {" "}
              {ComapnyDetailsDateTimeFormatter(data.createdAt, false)}
            </span>
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

function Resources() {
  const screenSize = useScreenSize();
  const getLimitOfResources = () => {
    switch (true) {
      case screenSize >= 5760:
        return 20;
      case screenSize >= 4320:
        return 15;
      case screenSize >= 2880:
        return 10;
      case screenSize >= 2560:
        return 9;
      case screenSize >= 2160:
        return 7;
      case screenSize >= 1792:
        return 6;
      case screenSize >= 1512:
        return 5;
      case screenSize >= 1200:
        return 4;
      case screenSize >= 992:
        return 3;
      case screenSize >= 768:
        return 2;
      default:
        return 1;
    }
  };
  let limit = getLimitOfResources();

  const [resourcesList, setResourcesList] = useState([]);
  const [paginationState, setPaginationState] = useState({
    pageNo: 1,
    count: 0,
  });
  const [search, setSearchState] = useState("");
  const history = useHistory();
  const [orderBy, setOrderBy] = useState("");
  const [playData, setPlayData] = useState({});
  const [isIframeOpen, setIframeOpen] = useState(false);
  const [videoId, setVideoId] = useState();

  const handleIframeModelClose = useCallback(() => {
    setIframeOpen(false);
    setPlayData({});
    setVideoId();
  }, []);
  const handlePlay = useCallback(
    (data) => {
      setVideoId(getYouTubeVideoId(data.video_link));
      setPlayData(data);
      setIframeOpen(true);
    },
    [getYouTubeVideoId]
  );

  const fetchResources = async (pageNumber) => {
    let pageNo = pageNumber || 1;
    // Set default values if parameters are not provided
    let queryParams = "";

    if (search !== "") {
      queryParams += "&title=" + search;
    }

    // Update the browser history
    history.push({
      pathname: history.location.pathname,
      search: "?page=" + pageNo + queryParams,
    });

    let url = `/faq-resources/resources?status=1&limit=${limit}`;

    if (pageNo) {
      url = url + `&page=${pageNo}`;
    }
    if (search) {
      url = url + `&name=${search}`;
    }

    if (orderBy !== "" && orderBy !== "all") {
      let order = orderBy === "Old to New" ? "asc" : "desc";
      url = url + `&order=${order}&sort=created_at`;
    } else {
      url = url + `&sort=order`;
    }
    let res = await ResourceAndFaqServices.getList(url);
    setResourcesList(res.data.data.data);
    setPaginationState({
      pageNo: res.data.data.currentPage,
      count: res.data.data.total,
    });
  };
  const handlePage = async (e) => {
    setPaginationState({ ...paginationState, pageNo: e });
    await fetchResources(e);
  };
  const totalPages = () => {
    if (paginationState.count === 0) {
      return 1;
    }
    return parseInt(Math.ceil(paginationState.count / limit));
  };
  const handleSearch = async () => {};
  const handleInput = (e) => {
    setSearchState(e.target.value);
  };
  useEffect(() => {
    fetchResources();
  }, [orderBy, search, screenSize]);
  return (
    <div>
      <div className="d-flex justify-content-space-between">
        <span className="title-text f-23 fw-700">Resources</span>
        <div className="d-flex g-10">
          <SearchComponent
            placeHolder="Search Video"
            handleSearch={handleSearch}
            handleInput={handleInput}
            value={search}
          />
          <DropdownList
            data={["New to Old", "Old to New"]}
            style={{ width: "120px" }}
            placeholder="Sort by"
            className="dropdown-list"
            onChange={(e) => setOrderBy(e)}
          />
        </div>
      </div>
      {resourcesList.length > 0 ? (
        <>
          <div className="grid-container">
            {resourcesList.map((item, index) => (
              <ResourceItem
                key={index}
                handlePlay={handlePlay}
                item={item}
                index={index}
              />
            ))}
          </div>
          <CustomPagination
            handlePage={handlePage}
            totalPages={totalPages}
            pageNo={paginationState.pageNo}
            className="right-pagination custom-pagination-new"
            pageSizeShow={false}
          />
        </>
      ) : (
        <NoDataAvailable text="Resource" />
      )}
      <VideoModal
        isOpen={isIframeOpen}
        toggle={handleIframeModelClose}
        videoId={videoId}
        title={playData?.title}
        data={playData}
      />
    </div>
  );
}

export default Resources;
