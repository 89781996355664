import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as echarts from "echarts";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Toast from "../../../../Utils/Toast";

const DoughnutChart = React.memo(
  forwardRef(({ data, downloadPDFRef }) => {
    let { dChartData } = data;
    const chartRefs = useRef([]);

    useEffect(() => {
      chartRefs.current.forEach((ref, index) => {
        if (ref) {
          let chartInstance = echarts.getInstanceByDom(ref);
          if (chartInstance) {
            chartInstance.dispose();
          }
          const chart = echarts.init(ref, null, { renderer: "svg" });

          const option = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              type: "plain",
              orient: "vertical",
              right: "15%", // Position the legend close to the right edge
              top: 20,
              bottom: 20,
              itemWidth: 16,
              itemHeight: 8,
              textStyle: {
                fontSize: 8,
              },
              width: dChartData[index].data.length > 5 ? "25%" : "20%", // Slightly reduce the legend width if needed
              itemGap: 5,
              formatter: function (name) {
                return echarts.format.truncateText(
                  name,
                  1000,
                  "12px Microsoft Yahei",
                  "…"
                );
              },
            },
            grid: {
              left: "0%", // Align the chart as far left as possible
              right: "25%", // Reduce the right margin to bring the chart closer to the legend
              top: "10%",
              bottom: "10%",
              containLabel: true, // Ensure the chart is fully contained within the grid
            },
            series: [
              {
                name: dChartData[index].title,
                type: "pie",
                radius: ["50%", "70%"],
                center: ["25%", "50%"], // Position the pie chart closer to the legend
                avoidLabelOverlap: false,
                label: {
                  show: true,
                  position: "center", // Keeps the label centered within the pie chart
                  formatter: dChartData[index].title,
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#333",
                },
                emphasis: {
                  label: {
                    show: false,
                    fontSize: "16",
                    fontWeight: "bold",
                  },
                },
                labelLine: {
                  show: false,
                },
                data: dChartData[index].data,
              },
            ],
          };

          chart.setOption(option);
        }
      });

      // Cleanup function to dispose charts
      return () => {
        chartRefs.current.forEach((ref) => {
          if (ref) {
            echarts.dispose(ref);
          }
        });
      };
    }, [dChartData]);

    useImperativeHandle(downloadPDFRef, () => ({
      downloadChart: async (format) => {
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [1152, 576], // px for higher resolution
        });
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const maxChartHeight = pageHeight / 2; // Each chart takes half the page height

        // Filter out null or undefined refs and limit to current dChartData length
        const validChartRefs = chartRefs.current.filter(
          (ref, index) => ref && index < dChartData.length
        );

        for (let i = 0; i < validChartRefs.length; i += 2) {
          if (i > 0) {
            pdf.addPage();
          }

          const chartRef1 = validChartRefs[i];
          const chartRef2 = validChartRefs[i + 1];

          try {
            const canvas1 = chartRef1
              ? await html2canvas(chartRef1, { scale: 2, useCORS: true })
              : null;
            const canvas2 = chartRef2
              ? await html2canvas(chartRef2, { scale: 2, useCORS: true })
              : null;

            if (canvas1) {
              const imgData1 = canvas1.toDataURL("image/png");
              const imgWidth1 = canvas1.width;
              const imgHeight1 = canvas1.height;
              const ratio1 = Math.min(
                pageWidth / imgWidth1,
                maxChartHeight / imgHeight1
              );
              const canvasWidth1 = imgWidth1 * ratio1;
              const canvasHeight1 = imgHeight1 * ratio1;

              pdf.addImage(
                imgData1,
                "PNG",
                (pageWidth - canvasWidth1) / 2, // Center horizontally
                0, // Position at the top
                canvasWidth1,
                canvasHeight1
              );
            }

            if (canvas2) {
              const imgData2 = canvas2.toDataURL("image/png");
              const imgWidth2 = canvas2.width;
              const imgHeight2 = canvas2.height;
              const ratio2 = Math.min(
                pageWidth / imgWidth2,
                maxChartHeight / imgHeight2
              );
              const canvasWidth2 = imgWidth2 * ratio2;
              const canvasHeight2 = imgHeight2 * ratio2;

              pdf.addImage(
                imgData2,
                "PNG",
                (pageWidth - canvasWidth2) / 2, // Center horizontally
                maxChartHeight, // Position at the bottom
                canvasWidth2,
                canvasHeight2
              );
            }
          } catch (error) {
            console.error("Error creating canvas for charts:", error);
          }
        }

        pdf.save(`Doughnut_chart.pdf`);
        Toast("Doughnut chart downloaded successfully", "success");
      },
    }));

    return (
      <div>
        <div className="chart-grid">
          {dChartData.map((_, index) => (
            <>
              <div
                key={index}
                className="chart-container"
                ref={(el) => {
                  chartRefs.current[index] = el;
                }}
              ></div>
            </>
          ))}
        </div>
      </div>
    );
  })
);

export default DoughnutChart;
